import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { ContentApi    } from 'sdc-cms-client'
import { update        } from 'sdc-mobx-stores'

import { AmplifyStore  } from '../amplify'

import { awsDataToEntry } from 'sdc-data-models'

import api from './api'

const typeID = '419eT9FKzVEfpNHb57DPol6JLRfkfBVk'

class BoardsStore extends AmplifyStore {

  @observable boardsByID = {}

  @observable recently = []

  reloading = null
  @observable domain    = null

  constructor(options) {
    super({
      ...options,
      typeID,
      name  : 'board',
      owned : true,
    })
    this.clearViewing = false
    autorun(() => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      } else {
        this.clearData()
      }
    })

    this.createToEntry = awsDataToEntry('createBoard')
    this.updateToEntry = awsDataToEntry('updateBoard')

    subscribe('network-changed', speed => {
      if (userStore.user.id) {
        this.reload()
        this.subscribeToAWS()
      }
    })

    subscribe('board-entry-created',  this.boardCreated)
    subscribe('board-entry-updated',  this.boardUpdated)
    subscribe('board-entry-selected', this.boardSelected)
  }

  reload = () => {
    if (this.domain) return;
    this.list({
      callback : this.parseAWS
    })()
  }

  subscribeToAWS = () => {
    if (this.domain) return;
    this.subscribeTo('onUpdateBoard')
  }

  @action
  clearData = () => {
    this.boardsByID = {}
    this.dataList   = []
  }

  @action
  loadAndSelect = domain => {
    this.domain = domain
    this.list({
      params   : { domain: { eq: domain } },
      callback : this.parseLoad,
    })()
  }

  parseAWS = deferred => action(data => {
    if (data?.listBoards?.items) {
      const payload = data.listBoards.items

      this.boardsByID = _.keyBy(payload, 'id')
      this.dataList   = _.orderBy(payload, 'name')

      this.updateRecently()
      if (this.recently.length > 0) {
        this.select(this.recently[0])()
      }
    }
    deferred.resolve(this.dataList)
  })

  parseLoad = deferred => action(data => {
    if (data?.listBoards?.items) {
      const payload = data.listBoards.items

      this.boardsByID = _.keyBy(payload, 'id')
      this.dataList   = _.orderBy(payload, 'name')

      if (this.dataList.length === 1) {
        this.select(this.dataList[0])()
      }
    }
    deferred.resolve(this.dataList)
  })

  @action
  updateRecently = () => {
    const recently = _.sortBy(this.dataList, z => z?.lastUsed || z?.created)
    this.recently  = recently.slice(-10).reverse()
  }

  boardSelected = board => {
    if (board && !this.domain) {
      const now = Math.floor(new Date().valueOf() / 1000)
      if (!board?.lastUsed || board.lastUsed + 10 < now) {
        setTimeout(this.setLastUsed(board,now), 50)
      }
    }
  }

  boardUpdated = board => {
    if (board) {
      this.patch(board)
    }
  }

  updateTheme = theme => {
    this.patch({
      id : this.selected.id,
      theme,
    })
  }

  setLastUsed = (board,ts) => action(() => {
    if (this.domain) return;
    if ((board.lastUsed || 0) + 10 < ts) {
      this.updateEntryField(board,'lastUsed',ts)
    }
  })

  patch = delta => {
    if (this.domain) return;
    this.api.patch(delta).then(response => {
      this.onUpdateBoard(response?.data?.updateBoard)
    }).catch(error => {
      console.warn('update failed', error)
    })
  }

  @action
  onUpdateBoard = board => {
    console.log('onUpdateBoard()',toJS(board))
    if (board) {
      this.dataList = update(this.dataList)(board)
      if (this.selected.id === board.id) {
        this.selected = board
      }
      this.updateRecently()
      if (this.recently.length > 0 && this.selected?.id !== this.recently[0].id) {
        this.select(this.recently[0])()
      }
    }
  }

}

export default ({...options}) => new BoardsStore({...options,api:api()})
