import React from 'react'
import { observer      } from 'mobx-react'

import { Label         } from 'sdc-i18n-react'

import { Page          } from '../layout'

import { BoardList     } from './list'
import { BoardDetails  } from './details'

export const Boards = observer(() => <Page>
  <h3 className="kasten">
    <Label value="nav.boards" />
  </h3>
  <BoardList />
  <BoardDetails />
</Page>)
