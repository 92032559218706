import React from 'react'
import { toJS             } from 'mobx'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingMode      } from 'sdc-cms-writing'
import { editingType      } from 'sdc-cms-writing'

import { mode             } from '../layout/mode'

export const FeatureDetails = inject('typesStore','featuresStore')(observer(({typesStore,featuresStore}) => {

  //if (!featuresStore.selected.id) return null
  if (editingType.typeID !== featuresStore.typeID) return null

  const data = {
    store     : featuresStore,
    dataStore : featuresStore,
    update    : featuresStore.update,
  }

  return <Provider {...data}>
    <EntryFieldsList showLabels={editingMode.isEditMode.get()} className="zettel-details" type="feature" fields={['type','title','description']} />
  </Provider>
}))

