import React from 'react'
import cn    from 'classnames'
import { inject, observer } from 'mobx-react'

import Amplify, { Auth, Hub, API, graphqlOperation, PubSub } from 'aws-amplify'

import { Route, Router    } from 'react-router-dom'

import { DragDropContext  } from 'react-beautiful-dnd'

import { userStore        } from 'sdc-auth-user'

import { Authenticated    } from 'sdc-auth-react'

import { editingConfig, VALUES_PLAIN } from 'sdc-data-models'

import { NavBar   } from './nav/bar'

import { Home     } from './home'

import { Boards   } from './boards'
import { Features } from './features'

import { history } from './index'

import awsDefaults from './aws-exports'

editingConfig.valuesMode = VALUES_PLAIN

console.info(`using redirect URL ${window.location.origin}/`)

const {oauth,...config} = awsDefaults
const awsConfig = {
  ...config,
  oauth: {
    ...oauth,
    redirectSignIn  : window.location.origin+'/',
    redirectSignOut : window.location.origin+'/',
  }
}

Amplify.configure(awsConfig)
//PubSub.configure(awsConfig)

const handleSignIn = data => {
  const payload = data.signInUserSession?.idToken?.payload
  if (payload) {
    const id = data.username.substring(7)
    console.log('signed in', id)
    console.log(payload.name)
    userStore.setUser({
      id    : 'Google_'+id,
      name  : payload.name,
      email : payload.email,
    })

  } else {
    setTimeout(obtainSignIn,100)
  }
}

const obtainSignIn = () => {
  Auth.currentAuthenticatedUser()
  .then(handleSignIn)
  .catch(error => {
    console.log('not signed in', error)
  })
}

@inject('languagesStore')
@observer
export class Main extends React.Component {

  componentDidMount() {
    Hub.listen("auth", ({ payload: {event, data}}) => {
      switch (event) {
        case "signIn":
          handleSignIn(data)
          break
        case "signOut":
          try {
            console.log("signed out")
            userStore.logout()
          } catch (error) {
            console.info(error)
          }
          break
      }
    })
    obtainSignIn()
  }

  render() {
    return <Router history={history}>
      <div lang={this.props.languagesStore.selectedLanguage}>

        <NavBar />

        <Route path="/"   exact component={Home} />

        <Route path="/boards"   component={Authenticated(Boards)} />
        <Route path="/features" component={Features} />

      </div>
    </Router>
  }
}
