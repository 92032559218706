import React from 'react'

import { Container, Row, Col } from 'reactstrap'

import { FeatureGallery } from './feature-gallery'

import './home.scss'

const items = [{
  src: '/feedback-board-1-en.png',
  altText: 'Feedback Board',
  caption: 'A Feedback Board',
  header: 'some header',
  key: '1',
}]

export const Home = () => <div className="home-page">
  <Container fluid>
    <Row>
      <Col md="3" style={{marginTop: '6rem', paddingLeft: '6rem'}}>
        <h1>Feedback Boards</h1>
        <ul>
          <li> with voting and commenting </li>
          <li> quick set up </li>
          <li> intuitive use </li>
        </ul>
        <h2> Discover the best ideas from your users.</h2>
      </Col>
      <Col md="9">
        <FeatureGallery />
      </Col>
    </Row>
  </Container>
</div>
