import 'react-app-polyfill/ie11'
import 'react-app-polyfill/stable'

import React    from 'react'
import ReactDOM from 'react-dom'
import qs       from 'qs'

import { AppContainer } from 'react-hot-loader'

import { autorun, configure } from 'mobx'
import { Provider } from 'mobx-react'
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router'
import { Router } from 'react-router'
import { createBrowserHistory } from 'history'

import Modal from 'react-modal'

import './custom.scss'
import 'font-awesome/css/font-awesome.css'

import { userStore } from 'sdc-auth-user'

import { appState  } from './app/state'
import { Main      } from './main'

import * as otherStores from './stores'


import { unregister } from './registerServiceWorker'


configure({
  enforceActions : 'always',
})

Modal.setAppElement('#root')

const baseHistory = createBrowserHistory()
const routingStore = new RouterStore()

export const history = syncHistoryWithStore(baseHistory, routingStore)

const stores = {
  routing: routingStore,
  ...otherStores
}

let authenticated = false

autorun(() => {
  if (userStore.state.authenticated && !authenticated) {
    console.log(`going NOT back in history, user = ${userStore.user.name}`)
    //history.goBack()
  }
  authenticated = userStore.state.authenticated
})
//history.listen((location,action) => {
//console.log(location.pathname, location.state)
const subdomain = window.location.hostname.split('.',1)[0]
if (subdomain === 'localhost') {
  const board = 'fb'
  //stores.boardsStore.loadAndSelect(board)
  //history.push('/features')
} else if (subdomain !== 'www' && subdomain !== 'app') {
  stores.boardsStore.loadAndSelect(subdomain)
  history.push('/features')
} else if (subdomain === 'app') {
  history.push('/features')
}

  // if (location.pathname === '/boards') {
  //   //stores.zettelStore.selectMRU()
  // }
  //sendPageView(location.pathname)
//})

const render = Component =>
ReactDOM.render(
  <AppContainer>
    <Provider {...stores} >
      <Router history={history} path="/" >
    	  <Component />
      </Router>
    </Provider>
  </AppContainer>,
  document.getElementById('root')
)

render(Main)

if (module.hot) {
  module.hot.accept('./main', () => render(Main))
}

unregister()
//registerServiceWorker(appState.setUpdateAvailable)
