import { API, graphqlOperation } from 'aws-amplify'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { UpdatingStore }  from 'sdc-cms-writing'

export class AmplifyStore extends UpdatingStore {

  constructor({
    owned,
    ...options
  }) {
    super(options)
    this.owned = owned
  }

  subscription = {}

  subscribeTo = (event,ownerID) => {
    if (this.subscription[event]) {
      try {
        this.subscription[event].unsubscribe()
      } catch(error) {
        console.error(error)
      }
    }

    const owner = userStore.user.id || ownerID
    const query = this.owned ? { owner } : undefined

    if (this.owned && !query) return;

    this.subscription[event] = API.graphql({
      query     : subscriptions[event],
      variables : query,
      authMode  : userStore.user.id ? undefined : 'API_KEY',
    }).subscribe({
      next  : update => this[event](update.value?.data?.[event]),
      error : error  => console.error(error.error.errors[0].message),
    })
  }
}
