import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'

import api from './api'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { requiredParam } from 'sdc-utilities'
import * as store        from 'sdc-mobx-stores'

import { ContentApi    } from 'sdc-cms-client'
import { editingMode   } from 'sdc-cms-writing'
import { editingType   } from 'sdc-cms-writing'
import { update, remove} from 'sdc-mobx-stores'

import { awsDataToEntry } from 'sdc-data-models'

import { AmplifyStore  } from '../amplify'

const typeID = 'jhlF3dsUJC2tfcHqI8eKKKjAREgcsTLe'

const sum = (acc, curr) => acc + curr

const diffUp = {
  1 : 2,
  3 : 3,
  6 : 4,
}
const diffDown = {
  3 : 2,
  6 : 3,
  10 : 4,
}

class VotesStore extends AmplifyStore {

  @observable votesByFeature = {}

  constructor({
    stateStore,
    boardsStore   = requiredParam('boardsStore'),
    featuresStore = requiredParam('featuresStore'),
    ...options
  }) {
    super({
      ...options,
      typeID,
      name  : 'vote',
      owned : true,
    })
    this.boardsStore   = boardsStore
    this.featuresStore = featuresStore
    autorun(() => {
      if (userStore.user.email) {
        this.reload()
        this.subscribeToAWS()
      } else {
        this.clearData()
      }
    })

    this.createToEntry = awsDataToEntry('createVote')
    this.updateToEntry = awsDataToEntry('updateVote')
    this.deleteToEntry = awsDataToEntry('deleteVote')

    subscribe('network-changed', speed => {
      this.reload()
      this.subscribeToAWS()
    })
  }

  subscribeToAWS = () => {
    if (!userStore.user.email) return;

    this.subscribeTo('onCreateVote',userStore.user.email)
    this.subscribeTo('onUpdateVote',userStore.user.email)
    this.subscribeTo('onDeleteVote',userStore.user.email)
  }

  handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
//    editingMode.setMode('edit')()
  }

  @action
  clearData = () => {
    this.dataList = []
    this.votesByFeature = {}
  }

  vote = (feature,direction) => action(e => {
    this.handleClick(e)
    this.featuresStore.select({})()
    this.feature = feature
    const vote = this.votesByFeature[feature.id]
    if (vote) {
      const up   = diffUp[vote.weight] || 1
      const down = diffDown[vote.weight] || 1
      const diff = vote.direction === direction ? up : -down
      const weight = vote.weight + diff
      console.log(`vote is now ${weight} ${weight ? vote.direction : ''} (${Math.abs(diff)} ${direction})`)
      if (weight === 0) {
        this.remove(vote)(e)
      } else {
        this.updateEntryField(vote,'weight',weight)
      }
    } else {
      this.create({
        feature   : feature.id,
        email     : userStore.user.email || 'l.u.cephir@gmail.com',
        direction,
        weight    : 1,
      })()
    }
  })

  loadAndUpdateVotes = feature => {
    this.load({
      apiCall  : this.api.listVoteWeights({ feature: { eq: feature.id } }),
      callback : this.parseVoteWeights(feature),
    })()
  }

  totalVotes = vote => vote.direction === 'up'   ? vote.weight : -vote.weight
  upVotes    = vote => vote.direction === 'up'   ? vote.weight : 0
  downVotes  = vote => vote.direction === 'down' ? vote.weight : 0

  updateVotes = (payload,feature,field,toCounts,delta) => {
    const votes = payload.map(toCounts).reduce(sum,0)
    if (feature[field] !== votes) {
      delta[field] = votes
    }
  }

  parseVoteWeights = feature => deferred => action(data => {
    if (data?.listVotes?.items) {
      const payload = data.listVotes.items
      const delta = {}
      this.updateVotes(payload,feature,'votes'    ,this.totalVotes,delta)
      this.updateVotes(payload,feature,'upVotes'  ,this.upVotes,   delta)
      this.updateVotes(payload,feature,'downVotes',this.downVotes, delta)
      if (Object.keys(delta).length) {
        this.featuresStore.updateEntryFields(feature,delta)
      }
    }
    deferred.resolve()
  })

  reload = () => {
    if (!userStore.user.email) return;
    this.list({
      params   : { email: { eq: userStore.user.email } },
      callback : this.parseAWS
    })()
  }

  parseAWS = deferred => action(data => {
    try {
      if (data?.listVotes?.items) {
        const payload = data.listVotes.items
        this.dataList = payload
        this.refreshData()
      }
    } catch(e) {
      console.error(e)
    }
    deferred.resolve(this.dataList)
  })

  // @action
  // search = e => {
  //   this.suche = e.target.value
  //   this.filters = {}
  //   this.updateFiltered()
  // }

  // toggle = filter => action(e => {
  //   this.filters = {
  //     [filter] : !this.filters[filter],
  //   }
  //   this.suche = ''
  //   this.updateFiltered()
  // })

  refreshData = () => {
    this.buildIndex()
  }

  @action
  buildIndex = () => {
    this.votesByFeature = _.keyBy(this.dataList, 'feature')
  }

  afterCreate = vote => {
    this.onCreateVote(vote)
  }

  afterUpdate = vote => {
    this.onUpdateVote(vote)
  }

  @action
  onCreateVote = vote => {
    console.log('onCreateVote()')
    if (vote && vote.feature === this.feature?.id) {
      this.dataList = update(this.dataList)(vote)
      this.refreshData()
      this.loadAndUpdateVotes(this.feature)
    }
  }

  @action
  onDeleteVote = vote => {
    console.log('onDeleteVote()')
    if (vote && vote.feature === this.feature.id) {
      this.reload()
      this.loadAndUpdateVotes(this.feature)
    }
  }

  @action
  onUpdateVote = vote => {
    console.log('onUpdateVote()')
    if (vote && vote.feature === this.feature.id) {
      this.dataList = update(this.dataList)(vote)
      this.refreshData()
      this.loadAndUpdateVotes(this.feature)
    }
  }

}

// const ordered = data => _.orderBy(data, ['votes','title',])


export default ({...options}) => new VotesStore({...options,api:api()})
