import React from 'react'
import cn    from 'classnames'
import { inject, observer } from 'mobx-react'

import { Auth          } from 'aws-amplify'

import { Link, NavLink } from 'react-router-dom'
import { Nav, Input    } from 'reactstrap'


import { userStore     } from 'sdc-auth-user'
import { UserName      } from 'sdc-auth-react'

import { AppVersion, Button   } from 'sdc-react-basics'
import { Label, LanguagesMenu } from 'sdc-i18n-react'


import { mode             } from '../layout/mode'

import { BoardMenu        } from '../boards/menu'
import { FeatureFilters   } from '../features/filters'

import { Settings         } from '../settings'

import './bar.scss'

const signIn  = () => Auth.federatedSignIn({provider: 'Google'})
const signOut = () => Auth.signOut()


export const NavBar = inject('boardsStore','featuresStore','myUserStore')(observer(({boardsStore,featuresStore,myUserStore}) => mode.state === 'menu' ?
<nav className="navbar navbar-expand-lg navbar-static-top">

  <div className={cn('nav-header', boardsStore.domain && 'mr-auto')}>
    <Link className="navbar-brand" to="/">
      <span><i className="fa fa-fw fa-columns" /></span>
      {!boardsStore.domain && <Label className="app-name" value="app.name" />}
      { boardsStore.domain && <span  className="app-name">{boardsStore.selected.name}</span>}
    </Link>
  </div>

  {!boardsStore.domain && <ul className={cn('nav navbar-nav', 'mr-auto')}>
    <li className="nav-item">
      <NavLink className="nav-link" to="/boards">
        <i className={'fa fa-table'}></i> <Label value="nav.boards" />
      </NavLink>
    </li>
    <BoardMenu nav />
    <li className="nav-item" style={{textAlign: 'center'}}>
      <NavLink className="nav-link" to="/features">
        <span><i className={'fa fa-files-o'} /> </span>
        <Label style={{display: 'inline-block', minWidth: '10rem'}} count={featuresStore.dataList.length} value="nav.features.filtered" only={featuresStore.filtered.length} />
      </NavLink>
    </li>
  </ul>}

  <FeatureFilters />
  <Settings />

  <AppVersion />

  <ul className="nav navbar-nav">
    {userStore.user.id && <li className="nav-item"><NavLink className="nav-link" to="/boards"><UserName /></NavLink></li>}
    <LanguagesMenu nav />
    {!boardsStore.domain && !userStore.user.id && <li className="nav-item"><Button className="nav-link" caption="action.login" onClick={signIn}  /></li>}
    {!boardsStore.domain &&  userStore.user.id && <li className="nav-item"><Button className="nav-link"    icon="power-off"    onClick={signOut} /></li>}
  </ul>

</nav>
: null))
