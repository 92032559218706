/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getComment = /* GraphQL */ `
  query GetComment($id: ID!) {
    getComment(id: $id) {
      id
      feature
      text
      name
      email
      track
      status
      createdAt
      updatedAt
    }
  }
`;
export const listComments = /* GraphQL */ `
  query ListComments(
    $filter: ModelCommentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listComments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        feature
        text
        name
        email
        track
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getBoard = /* GraphQL */ `
  query GetBoard($id: ID!) {
    getBoard(id: $id) {
      id
      owner
      name
      status
      domain
      features
      pending
      open
      suggested
      planned
      inProgress
      done
      notPlanned
      deleted
      closed
      theme
      hue
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const listBoards = /* GraphQL */ `
  query ListBoards(
    $filter: ModelBoardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listBoards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        status
        domain
        features
        pending
        open
        suggested
        planned
        inProgress
        done
        notPlanned
        deleted
        closed
        theme
        hue
        createdAt
        updatedAt
        lastUsed
      }
      nextToken
    }
  }
`;
export const getFeature = /* GraphQL */ `
  query GetFeature($id: ID!) {
    getFeature(id: $id) {
      id
      board
      owner
      name
      email
      title
      type
      description
      status
      votes
      upVotes
      downVotes
      comments
      createdAt
      updatedAt
    }
  }
`;
export const listFeatures = /* GraphQL */ `
  query ListFeatures(
    $filter: ModelFeatureFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFeatures(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        board
        owner
        name
        email
        title
        type
        description
        status
        votes
        upVotes
        downVotes
        comments
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getVote = /* GraphQL */ `
  query GetVote($id: ID!) {
    getVote(id: $id) {
      id
      feature
      email
      track
      direction
      weight
      createdAt
      updatedAt
      owner
    }
  }
`;
export const listVotes = /* GraphQL */ `
  query ListVotes(
    $filter: ModelVoteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVotes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        feature
        email
        track
        direction
        weight
        createdAt
        updatedAt
        owner
      }
      nextToken
    }
  }
`;
