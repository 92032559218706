/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateComment = /* GraphQL */ `
  subscription OnCreateComment {
    onCreateComment {
      id
      feature
      text
      name
      email
      track
      status
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateComment = /* GraphQL */ `
  subscription OnUpdateComment {
    onUpdateComment {
      id
      feature
      text
      name
      email
      track
      status
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteComment = /* GraphQL */ `
  subscription OnDeleteComment {
    onDeleteComment {
      id
      feature
      text
      name
      email
      track
      status
      createdAt
      updatedAt
    }
  }
`;
export const onCreateBoard = /* GraphQL */ `
  subscription OnCreateBoard($owner: String) {
    onCreateBoard(owner: $owner) {
      id
      owner
      name
      status
      domain
      features
      pending
      open
      suggested
      planned
      inProgress
      done
      notPlanned
      deleted
      closed
      theme
      hue
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onUpdateBoard = /* GraphQL */ `
  subscription OnUpdateBoard($owner: String) {
    onUpdateBoard(owner: $owner) {
      id
      owner
      name
      status
      domain
      features
      pending
      open
      suggested
      planned
      inProgress
      done
      notPlanned
      deleted
      closed
      theme
      hue
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onDeleteBoard = /* GraphQL */ `
  subscription OnDeleteBoard($owner: String) {
    onDeleteBoard(owner: $owner) {
      id
      owner
      name
      status
      domain
      features
      pending
      open
      suggested
      planned
      inProgress
      done
      notPlanned
      deleted
      closed
      theme
      hue
      createdAt
      updatedAt
      lastUsed
    }
  }
`;
export const onCreateFeature = /* GraphQL */ `
  subscription OnCreateFeature($owner: String) {
    onCreateFeature(owner: $owner) {
      id
      board
      owner
      name
      email
      title
      type
      description
      status
      votes
      upVotes
      downVotes
      comments
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFeature = /* GraphQL */ `
  subscription OnUpdateFeature($owner: String) {
    onUpdateFeature(owner: $owner) {
      id
      board
      owner
      name
      email
      title
      type
      description
      status
      votes
      upVotes
      downVotes
      comments
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFeature = /* GraphQL */ `
  subscription OnDeleteFeature($owner: String) {
    onDeleteFeature(owner: $owner) {
      id
      board
      owner
      name
      email
      title
      type
      description
      status
      votes
      upVotes
      downVotes
      comments
      createdAt
      updatedAt
    }
  }
`;
export const onCreateVote = /* GraphQL */ `
  subscription OnCreateVote($owner: String) {
    onCreateVote(owner: $owner) {
      id
      feature
      email
      track
      direction
      weight
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onUpdateVote = /* GraphQL */ `
  subscription OnUpdateVote($owner: String) {
    onUpdateVote(owner: $owner) {
      id
      feature
      email
      track
      direction
      weight
      createdAt
      updatedAt
      owner
    }
  }
`;
export const onDeleteVote = /* GraphQL */ `
  subscription OnDeleteVote($owner: String) {
    onDeleteVote(owner: $owner) {
      id
      feature
      email
      track
      direction
      weight
      createdAt
      updatedAt
      owner
    }
  }
`;
