import axios  from 'axios'

import { makeTypesStore, makeControlsStore, makeHelpStore, makeIndexStore }  from 'sdc-cms-client'
import { editingMode      } from 'sdc-cms-writing'
import { makeEditingStore } from 'sdc-cms-writing'
import { makeCmsLanguagesStore, makeCmsLabelsStore } from 'sdc-i18n-cms'
import { languagesStore, labelsStore } from 'sdc-i18n-stores'
//import { adSettings, makeAdsStore } from 'sdc-amazon-ads'

import { publish          } from 'sdc-publish-subscribe'

import usersStore           from './data/users/store'

import makeBoardsStore   from './boards/store'
import makeFeaturesStore from './features/store'
import makeVotesStore    from './votes/store'

export { authStore } from 'sdc-auth-store'
export { userStore } from 'sdc-auth-user'

export { appState    } from './app/state'
export { myUserStore } from './user/store'

languagesStore.selectLanguage('de')()

labelsStore.addTranslation('en.action.login','Login')
labelsStore.addTranslation('de.action.login','Anmelden')

editingMode.setMode('edit')()

const cmsBase = 'https://www.headless-cms.io/api/'
const spaceID = 'TTFwpY94tnFowwXjncLIdDiV0Fc5utg0'
const backend = axios.create({
  withCredentials : true,
  baseURL         : cmsBase,
})

const connection = navigator.connection || navigator.mozConnection || navigator.webkitConnection
if (connection) {
  connection.addEventListener('change', () => {
    console.warn('connection changed to', connection.downlink, connection)
    if (connection.downlink === 0) {
      publish('network-lost')
    } else {
      publish('network-changed', connection.downlink)
    }
  })
}

//const adsStore        = makeAdsStore(       {cmsBase,typeID:'QeJc3lq7Xa0pWXMOqthQnfSBSbsHHmDM'})

export const cmsLanguagesStore = makeCmsLanguagesStore({ backend, spaceID })
export const cmsLabelsStore    = makeCmsLabelsStore(   { backend, typeID : 'lN7aNBiTeO6tUn5fslMRggWCWZQEVnjt', accessKey : '0B80oB9idibCtKrybfpbu82ldq28O6DK' })
export const typesStore        = makeTypesStore(       { backend })
export const editingStore      = makeEditingStore(     { backend, typesStore })
export const controlsStore     = makeControlsStore(    { backend, typeID : 'co38ne9yH0sOi8bZoxZ34IPOEyov7uAC' })
export const helpStore         = makeHelpStore(        { backend, labelsStore })

export const boardsStore       = makeBoardsStore(      {          typesStore, editingStore })
export const featuresStore     = makeFeaturesStore(    {          typesStore, editingStore, boardsStore })
export const votesStore        = makeVotesStore(       {          typesStore, editingStore, boardsStore, featuresStore })

export const indexStore        = makeIndexStore(       { backend, typesStore, editingStore, indexProvider : boardsStore })

//adSettings.setSize(160)

typesStore.setSpaceID(spaceID)

controlsStore.setTypeID('co38ne9yH0sOi8bZoxZ34IPOEyov7uAC')

export {
//  adsStore,
  usersStore,

  labelsStore,
  languagesStore,

}
