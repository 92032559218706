import { toJS } from 'mobx'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

export default () => ({
  create : entry  => () => API.graphql(graphqlOperation(mutations.createBoard, { input : toJS(entry) })),
  list   : filter => () => API.graphql({
    query     : queries.listBoards,
    variables : { limit: 1000, filter, },
    authMode  : filter ? 'API_KEY' : undefined,
  }),
  update : delta  => () => API.graphql(graphqlOperation(mutations.updateBoard, { input : toJS(delta) })),
  delete : entry  => () => {},
  patch  : delta  =>       API.graphql(graphqlOperation(mutations.updateBoard, { input : toJS(delta) })),
})
