import { action, observable } from 'mobx'

import { userStore } from 'sdc-auth-user'

const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

class UserStore {

  @observable name  = ''
  @observable email = ''
  @observable valid = false

  constructor() {
    this.loadFromStorage()
  }

  @action
  clear = e => {
    this.name = ''
    this.email = ''
    this.valid = false
    window.localStorage.removeItem('feature-board-name')
    window.localStorage.removeItem('feature-board-email')
    this.publishUser()
  }

  @action
  loadFromStorage = () => {
    this.loadData()
    if (this.email) {
      this.validate()
      this.publishUser()
    }
  }

  @action
  handleEmailChange = e => {
    this.email = e.target.value
    this.validate()
  }

  @action
  handleNameChange = e => {
    this.name = e.target.value
    this.validate()
  }

  @action
  handleKeyDown = e => {
    if (e.key === 'Enter') {
      this.submit(e)
    }
  }

  @action
  submit = e => {
    if (this.valid) {
      this.saveData()
      this.publishUser()
    }
  }

  @action
  loadData = () => {
    this.email = window.localStorage.getItem('feature-board-email') || ''
    this.name  = window.localStorage.getItem('feature-board-name') || ''
  }

  saveData = () => {
    window.localStorage.setItem('feature-board-email', this.email)
    window.localStorage.setItem('feature-board-name' , this.name)
  }

  publishUser = () => {
    userStore.setUser({
      email: this.email,
      name : this.name,
    })
  }

  @action
  validate = () => {
    this.valid = this.name && re.test((this.email || '').toLowerCase())
  }

}

export const myUserStore = new UserStore()
