import   React           from 'react'
import { inject, observer } from 'mobx-react'
import   cn              from 'classnames'

import { Container, Row, Col } from 'reactstrap'

import { editingMode   } from 'sdc-cms-writing'
import { userStore     } from 'sdc-auth-user'
import { Button        } from 'sdc-react-basics'

import { Page          } from '../layout'

import { FeatureDetails} from './details'
import { FeatureList   } from './list'
import { FeatureFilters} from './filters'
import { FeatureCard   } from './card'
import { StatusButton  } from './status-button'

import './features.scss'


export const Features = inject('boardsStore','featuresStore')(observer(({boardsStore,featuresStore}) =>
<Page>
  <Container fluid className="feature-board">
    <Row>
      <Col className={cn(featuresStore.selected.id && 'show-border')}>
        <div>
          <Container fluid>
            <Row>
              {featuresStore.filtered.map(feature => <FeatureCard feature={feature} key={feature.id} />)}
            </Row>
          </Container>
        </div>
      </Col>
      {featuresStore.selected.id && <Col md="6">
        <Container fluid style={{marginTop: '8px'}} className="feature-details">
          <Row>
            {userStore.user.id && <Col md="1" className="actions">
              <StatusButton icon="star-o"         status="pending"    />
              <StatusButton icon="newspaper-o"    status="suggested"  />
              <StatusButton icon="calendar"       status="planned"    />
              <StatusButton icon="tasks"          status="inProgress" />
              <StatusButton icon="check-square-o" status="done"       />
              <StatusButton icon="ban"            status="notPlanned" />
              <StatusButton icon="trash-o"        status="deleted"    />
            </Col>}
            <Col md={userStore.user.id ? 10 : 11} style={{marginTop: '12px', paddingLeft: '36px'}}>
              <FeatureDetails />
            </Col>
            <Col md="1" className="actions">
              <Button icon="times" onClick={featuresStore.clearSelected} />
              {(featuresStore.featuresByID[featuresStore.selected.id] || userStore.user.id) && editingMode.isViewMode.get() && <Button icon="edit" onClick={featuresStore.edit} />}
              {(featuresStore.featuresByID[featuresStore.selected.id] || userStore.user.id) && editingMode.isEditMode.get() && <Button icon="eye"  onClick={editingMode.setMode('view')} />}
            </Col>
          </Row>
        </Container>
      </Col>}
    </Row>
  </Container>
</Page>
))
