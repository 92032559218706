import   React              from 'react'
import { inject, observer } from 'mobx-react'

import { Input, InputGroup, InputGroupAddon } from 'reactstrap'

import { Label   } from 'sdc-i18n-react'
import { userStore        } from 'sdc-auth-user'
import { CreateButton     } from 'sdc-react-basics'

import { FilterButton     } from './filter-button'

const selectAll = e => {
  e.target.select()
}

export const FeatureFilters = inject('boardsStore','featuresStore','labelsStore')(observer(({boardsStore,featuresStore,labelsStore}) => {

  const handleKeyDown = e => {
    if (e.key === 'Escape') {
      featuresStore.toggle('open')()
    }
  }

  return <div className="mr-auto feature-search">
    <InputGroup className="filters">
      <InputGroupAddon addonType="prepend" ><CreateButton
          title="feature.create"
          style={{border: 'solid 1px #999'}}
          disabled={!userStore.user.email}
          onClick={featuresStore.createFeature}
      /></InputGroupAddon>

      <FilterButton icon="star-o"         toggle="pending"    color="#c93" />

      <FilterButton icon="map-o"          toggle="open"       style={{marginLeft: '12px'}} />
      <FilterButton icon="newspaper-o"    toggle="suggested"  style={{marginLeft: '4px'}} color="#aa3" />
      <FilterButton icon="calendar"       toggle="planned"    color="#77c" />
      <FilterButton icon="tasks"          toggle="inProgress" color="#79a" />

      <Input style={{marginTop: '2px', marginLeft: '12px'}} data-id={labelsStore.lastUpdate} placeholder={labelsStore.labelFor('feature.search')} value={featuresStore.suche} onChange={featuresStore.search} onFocus={selectAll} autoFocus onKeyDown={handleKeyDown} />
      <FilterButton icon="times"          type="append"/>

      <FilterButton icon="check-square-o" toggle="done"       style={{marginLeft: '12px'}} color="#7b7" />
      <FilterButton icon="ban"            toggle="notPlanned" color="#d77" />
      {!boardsStore.domain && <FilterButton icon="trash-o" toggle="deleted" color="#a33" />}
      <FilterButton icon="credit-card"    toggle="closed"     style={{marginLeft: '4px'}} />

    </InputGroup>
  </div>
}))
