import { action, autorun, observable, toJS } from 'mobx'

import _ from 'lodash'
import moment from 'moment'
import 'moment/locale/de'

import converter from 'xml-js'

import api from './api'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as subscriptions from '../graphql/subscriptions'

import { userStore     } from 'sdc-auth-user'
import { subscribe     } from 'sdc-publish-subscribe'
import { requiredParam } from 'sdc-utilities'
import * as store        from 'sdc-mobx-stores'
import { languagesStore} from 'sdc-i18n-stores'

import { ContentApi    } from 'sdc-cms-client'
import { editingMode   } from 'sdc-cms-writing'
import { editingType   } from 'sdc-cms-writing'
import { update, remove} from 'sdc-mobx-stores'

import { awsDataToEntry } from 'sdc-data-models'

import { AmplifyStore  } from '../amplify'

const typeID = 'jhlF3dsUJC2tfcHqI8eKKKjAREgcsTLe'


class FeaturesStore extends AmplifyStore {

  @observable featuresByID    = {}

  @observable suche    = ''

  @observable filters  = {
    open: true,
  }

  @observable filtered = []

  boardID = null
  partID  = null

  constructor({
    stateStore,
    boardsStore = requiredParam('boardsStore'),
    ...options
  }) {
    super({
      ...options,
      typeID,
      name  : 'feature',
      owned : true,
    })
    this.clearViewing = false
    this.boardsStore  = boardsStore
    autorun(() => {
      if (userStore.user.id) {
        this.subscribeToAWS()
        this.setInitialFilter()
      } else if (!boardsStore.domain) {
        this.clearData()
      }
    })
    autorun(() => {
      if (boardsStore.selected.id && this.boardID !== boardsStore.selected.id) {
        this.boardID = boardsStore.selected.id
        this.reload()
      }
    })
    autorun(() => {
      if (boardsStore.domain) {
        this.subscribeToAWS()
      }
    })
    autorun(() => {
      if (languagesStore.selectedLanguage) {
        moment.locale(languagesStore.selectedLanguage)
      }
    })

    this.createToEntry = awsDataToEntry('createFeature')
    this.updateToEntry = awsDataToEntry('updateFeature')
    this.deleteToEntry = awsDataToEntry('deleteFeature')

    subscribe('network-changed', speed => {
      this.subscribeToAWS()
    })

    subscribe('feature-entry-created',  this.featureCreated)
    subscribe('feature-entry-selected', this.featureSelected)
  }

  subscribeToAWS = () => {
    const userID = userStore.user.id || this.boardsStore.selected?.owner
    this.subscribeTo('onCreateFeature',userID)
    this.subscribeTo('onUpdateFeature',userID)
    //this.subscribeTo('onDeleteFeature',userID)
  }

  setInitialFilter = () => {
    if (this.initiallyFiltered) return;
    if (_.find(this.dataList,{status:'pending'}))
      this.limitTo('pending')
    else
      this.limitTo('suggested')
    this.initiallyFiltered = true
  }

  handleClick = e => {
    e.preventDefault()
    e.stopPropagation()
  }

  @action
  clearData = () => {
    this.dataList = []
    this.filtered = []

    this.featuresByID = {}

    this.suche    = ''
  }

  @action
  createFeature = e => {
    editingMode.setMode('edit')()
    this.create({
      board  : this.boardsStore.selected.id,
      owner  : this.boardsStore.selected.owner,
      name   : userStore.user.id ? 'Admin' : userStore.user.name,
      email  : userStore.user.email,
      status : 'pending',
    })()
  }

  reload = () => {
    this.initiallyFiltered = false
    this.list({
      params   : { board: { eq: this.boardID } },
      callback : this.parseAWS
    })()
  }

  parseAWS = deferred => action(data => {
    if (data?.listFeatures?.items) {
      const payload = data.listFeatures.items
      this.dataList = ordered(payload)
      this.refreshData()
    }
    deferred.resolve(this.dataList)
    if (!userStore.user.id) {
      this.loadMyFeatures()
    }
  })

  loadMyFeatures = () => {
    this.load({
      apiCall  : this.api.listMyFeatures({
        board: { eq: this.boardID },
        email: { eq: userStore.user.email },
        name : { eq: userStore.user.name },
      }),
      callback : this.parseMyFeatures,
    })()
  }

  parseMyFeatures = deferred => action(data => {
    if (data?.listFeatures?.items) {
      this.featuresByID = _.keyBy(data?.listFeatures?.items,'id')
    }
    deferred.resolve()
  })

  featureSelected = feature => {
    if (this.featuresByID[feature?.id] || userStore.user.id) {
      //editingMode.setMode('edit')()
    } else
      editingMode.setMode('view')()
  }

  @action
  search = e => {
    this.suche = e.target.value
    this.filters = {}
    this.clearSelected()
    this.updateFiltered()
  }

  @action
  limitTo = filter => {
    this.filters = {
      [filter]: true,
    }
    this.suche = ''
    this.updateFiltered()
  }

  toggle = filter => action(e => {
    this.filters = {
      [filter] : !this.filters[filter],
    }
    this.suche = ''
    this.clearSelected()
    this.updateFiltered()
  })

  refreshData = () => {
    this.patchData()
    this.updateFiltered()
  }

  @action
  patchData = () => {
    this.dataList.forEach(feature => {
      if (feature.owner === userStore.user.id) {
        if (!feature.name) {
          this.updateEntryField(feature,'name',userStore.user.name)
        }
        if (!feature.email) {
          this.updateEntryField(feature,'email',userStore.user.email)
        }
        if (!feature.status) {
          this.updateEntryField(feature,'status','suggested')
        }
        if ((feature.votes || 0) !== (feature.upVotes || 0) - (feature.downVotes || 0)) {
          this.updateEntryField(feature,'votes',(feature.upVotes || 0) - (feature.downVotes || 0))
        }
        if (feature.votes === null) {
          this.updateEntryField(feature,'votes',0)
        }
      }
    })
  }

  @action
  updateFiltered = () => {
    const filtered = this.dataList.filter(this.filter)
    if (this.filters.suggested)
      this.filtered = orderByDate(filtered)
    else
      this.filtered = ordered(filtered)
  }

  filter = feature => {
    if (!this.filters.pending && this.boardsStore.domain && feature.status === 'pending') return false
    if (!this.filters.deleted && this.boardsStore.domain && feature.status === 'deleted') return false
    if (this.suche.length && (!feature.title || feature.title.toLowerCase().indexOf(this.suche.toLowerCase()) < 0)) return false
    if (this.filters.pending)    return feature.status === 'pending';
    if (this.filters.suggested)  return feature.status === 'suggested';
    if (this.filters.open)       return isOpen(feature);
    if (this.filters.planned)    return feature.status === 'planned';
    if (this.filters.inProgress) return feature.status === 'inProgress';
    if (this.filters.done)       return feature.status === 'done';
    if (this.filters.notPlanned) return feature.status === 'notPlanned';
    if (this.filters.deleted)    return feature.status === 'deleted';
    if (this.filters.closed)     return isClosed(feature);
    return true;
  }

  edit = e => {
    const feature = this.selected
    editingMode.setMode('edit')()
    setTimeout(this.select(feature),10)
  }

  featureCreated = entry => {
    this.updateBoardSize()
  }

  afterRemove = entry => {
    if (entry.id === this.selected.id) {
      this.select({})()
    }
  }

  totalCount = feature => true
  filteredCount = (...args) => feature => args.map().reduce((prev,curr) => {
    console.log('filtering',prev,curr)
    return !!prev || feature.status === curr
  })

  updateCount = (field,stati,delta) => {

    const toFeaturesCountWithStatus = status => this.dataList.filter(feature => feature.status === status).length
    const sum = (acc, curr) => (acc || 0) + (curr || 0)
    const getCount = () => {
      if (typeof stati === 'number')
        return stati
      else if (typeof stati === 'string')
        return toFeaturesCountWithStatus(stati)
      else
        return stati.map(toFeaturesCountWithStatus).reduce(sum)
    }
    const count = getCount()
    if (this.boardsStore.selected[field] !== count) {
      delta[field] = count
    }
  }

  @action
  updateBoardSize = () => {
    //if (!this.boardsStore.domain && userStore.user.id) {
    if (this.boardsStore.selected.id) {

      const delta = {}

      this.updateCount('features'  ,this.dataList.length,delta)
      this.updateCount('pending'   ,['pending']   ,delta)
      this.updateCount('suggested' ,['suggested'] ,delta)
      this.updateCount('planned'   ,['planned']   ,delta)
      this.updateCount('inProgress',['inProgress'],delta)
      this.updateCount('done'      ,['done']      ,delta)
      this.updateCount('notPlanned',['notPlanned'],delta)
      this.updateCount('deleted'   ,['deleted']   ,delta)

      this.updateCount('open'      ,['suggested','planned','inProgress'],delta)
      this.updateCount('closed'    ,['done','notPlanned','deleted'     ],delta)

      if (Object.keys(delta).length) {
        this.boardsStore.updateEntryFields(this.boardsStore.selected,delta)
      }
    }
  }

  updateStatus = (feature,status) => action(e => {
    this.handleClick(e)
    this.updateEntryField(feature,'status',status)
    if (this.selected.id === feature.id) {
      this.editingStore.setSelected(this.selected)
      this.editingStore.setBuffers(this.selected)
    }
    this.updateFiltered()
    this.updateBoardSize()
  })

  @action
  onCreateFeature = feature => {
    if (feature && feature.board === this.boardsStore.selected.id) {
      this.dataList = ordered(update(this.dataList)(feature))
      if (this.selected?.id === feature?.id) {
        this.selected = feature
        if (editingMode.isViewMode.get()) {
          this.editingStore.setSelected(this.selected)
          this.editingStore.setBuffers(this.selected)
        }
      }
      this.refreshData()
      this.updateBoardSize()
    }
  }

  @action
  onDeleteFeature = feature => {
    if (feature && feature.board === this.boardsStore.selected.id) {
      this.dataList = ordered(remove(this.dataList)(feature))
      this.select({})()
      this.refreshData()
      this.updateBoardSize()
    }
  }

  @action
  onUpdateFeature = feature => {
    if (feature && feature.board === this.boardsStore.selected.id) {
      this.dataList = ordered(update(this.dataList)(feature))
      this.refreshData()
      this.updateBoardSize()
    }
  }

}

const ordered = data => _.orderBy(data, ['votes','title',],['desc','asc'])
const orderByDate = data => _.orderBy(data, ['createdAt','votes','title',],['desc','desc','asc'])

const isOpen   = feature => feature.status === 'suggested' || feature.status === 'planned'    || feature.status === 'inProgress'
const isClosed = feature => feature.status === 'done'      || feature.status === 'notPlanned' || (userStore.user.id && feature.status === 'deleted')


export default ({...options}) => new FeaturesStore({...options,api:api()})
