import   React              from 'react'
import { inject, observer } from 'mobx-react'

import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'

import { userStore } from 'sdc-auth-user'
import { Label     } from 'sdc-i18n-react'

import { BoardName } from './name'

export const BoardMenu = inject('boardsStore')(observer(({boardsStore,nav}) => userStore.user.id ?
<UncontrolledDropdown>
  <DropdownToggle caret nav={!!nav} className="board-menu">
    <BoardName board={boardsStore.selected} />
  </DropdownToggle>
  <DropdownMenu>
    {boardsStore.dataList.filter(board => board.id).map(board =>
      <DropdownItem key={board.id}
          className="board-menu-entry"
          active={board.id === boardsStore.selected.id}
          onClick={boardsStore.select(board)}>
        <div className="clearfix">
          {board.features && <span className="details pull-right">(<Label value="nav.features.all" count={board.features} />)</span>}
          <BoardName className="board-name" board={board} />
        </div>
      </DropdownItem>
    )}
  </DropdownMenu>
</UncontrolledDropdown> : null))
