import React from 'react'
import { inject, observer } from 'mobx-react'

import { userStore     } from 'sdc-auth-user'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'
import { num, col  } from '../components/table/columns'

const columns = (store) => ([
  column.createDelete({
    type     : store.name,
    onCreate : store.create({
      owner : userStore.user.id,
    }),
  }),
  num('features',  {width:120}),
  num('suggested', {width:120}),
  num('planned',   {width:120}),
  num('inProgress',{width:120}),
  num('done',      {width:120}),
  num('notPlanned',{width:120}),
  num('deleted',   {width:120}),
  col('domain',    {width:120}),
  column.name(),
  column.ts({accessor: 'lastUsed',width: 150}),
])

export const BoardList = inject('boardsStore')(observer(({boardsStore}) =>
<DataTable store={boardsStore}
  columns={columns(boardsStore)}
/>))
