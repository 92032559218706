import React from 'react'
import { inject, observer } from 'mobx-react'

import { Input, InputGroup, InputGroupAddon } from 'reactstrap'

import { userStore     } from 'sdc-auth-user'
import { Button        } from 'sdc-react-basics'

import { NameInput     } from '../user/name'
import { EmailInput    } from '../user/email'

export const Settings = inject('boardsStore','myUserStore')(observer(({boardsStore,myUserStore}) => !boardsStore.domain ? null :
  <div>
    <InputGroup className="settings">
      <NameInput />
      <EmailInput />
      <InputGroupAddon addonType="append" style={{marginLeft: '4px'}}>
        <Button icon="power-off" title="user.clear" onClick={myUserStore.clear} disabled={!userStore.user.email || !userStore.user.name} />
      </InputGroupAddon>
      { userStore.user.email && userStore.user.name && <InputGroupAddon addonType="append" style={{marginLeft: '10px'}}>
        <Button icon="cogs" title="email.clear" onClick={myUserStore.clear} />
      </InputGroupAddon>}
    </InputGroup>
  </div>
))
