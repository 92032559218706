import   React              from 'react'
import   moment             from 'moment'
import   cn                 from 'classnames'
import { inject, observer } from 'mobx-react'

import { Container, Row, Col, Card, CardBody, CardTitle, CardText } from 'reactstrap'

import { userStore     } from 'sdc-auth-user'
import { Label         } from 'sdc-i18n-react'
import { languagesStore} from 'sdc-i18n-stores'
import { MarkdownPanel } from 'sdc-markdown-viewer'
import { Button        } from 'sdc-react-basics'

import { StatusButton  } from './status-button'

const MAX_VOTES = 10


const VoteButton = inject('featuresStore','labelsStore','votesStore')(observer(({featuresStore,labelsStore,votesStore,feature,direction}) => {
  const vote = votesStore.votesByFeature[feature.id]
  const active = vote?.direction === direction
  return <Button as="div" icon={'caret-'+direction}
      title={labelsStore.labelFor(titleForVote(vote,direction),{maxVotes: MAX_VOTES, votes: vote?.weight})}
      disabled={(feature.status === 'pending' && !featuresStore.featuresByID[feature.id]) || !userStore.user.email || (vote?.direction === direction && vote?.weight >= MAX_VOTES)}
      className={cn('vote', direction, active && 'active')}
      onClick={votesStore.vote(feature,direction)} />
}))

const featureIcons = {
  enhancement : 'plus-square-o',
  improvement : 'line-chart',
  bug         : 'bug',
}
const featureColors = {
  enhancement : '#009',
  improvement : '#090',
  bug         : '#900',
}
const featureSizes = {
  enhancement : '7em',
  improvement : '5em',
  bug         : '6em',
}

const FeatureType = observer(({feature}) => feature.type ?
  <span
      className={'fa fa-'+featureIcons[feature.type]}
      style={{fontSize: featureSizes[feature.type], color: featureColors[feature.type], opacity: '.33', position: 'absolute', transform: 'translateX(-50%)'}} />
: null)

const titleForVote = (vote,direction) => {
  if (!vote?.weight) return 'vote.action.'+direction
  if (vote?.weight >= MAX_VOTES) return (vote?.direction === direction) ? 'vote.max.'+direction : 'vote.revoke.'+direction
  return (vote?.direction === direction) ? 'vote.more.'+direction : 'vote.revoke.'+direction
}

export const FeatureCard = inject('boardsStore','featuresStore','votesStore')(observer(({boardsStore,featuresStore,votesStore,feature}) =>
  <Col md={featuresStore.selected.id ? 6 : 3}>
    <Card className={cn('feature', feature.status, feature.id === featuresStore.selected.id && 'selected')} onClick={featuresStore.select(feature)}>
      <CardBody>
        <Container>
          <Row style={{height: '8.5rem'}}>
            <Col md="3" className="votes">
              <FeatureType feature={feature} />
              <VoteButton feature={feature} direction="up" />
              <div className="count">{(feature.upVotes || 0) - (feature.downVotes || 0)}</div>
              <VoteButton feature={feature} direction="down" />
              <Label as="div" value="feature.votes" />
              <Label as="div" className={cn('status', feature.status)} value={'feature.status.'+(feature.status || 'suggested')} />
            </Col>
            <Col md={boardsStore.domain ? 9 : 8} style={{maxHeight: '8rem', overflowY: 'hidden'}}>
              <CardTitle>{feature.title}</CardTitle>
              <MarkdownPanel className="card-text" text={feature.description} />
            </Col>
            {!boardsStore.domain && <Col md="1" className="actions">
              <StatusButton feature={feature} icon="newspaper-o"    status="suggested"  />
              <StatusButton feature={feature} icon="calendar"       status="planned"    />
              <StatusButton feature={feature} icon="tasks"          status="inProgress" />
              <StatusButton feature={feature} icon="check-square-o" status="done"       />
              <StatusButton feature={feature} icon="ban"            status="notPlanned" />
            </Col>}
          </Row>
          <Row style={{height: '1rem', fontSize: '75%', color: 'black'}}>
            <Col md="3">
            </Col>
            <Col data-locale={languagesStore.selectedLanguage}>
              <Label padded value="feature.suggested.by" />
              <b>{feature.name}</b>
              <Label padded value="feature.suggested.on" />
              {moment(feature.createdAt).format('L')}.
            </Col>
          </Row>
        </Container>
      </CardBody>
    </Card>
  </Col>
))
