import React from 'react'
import { inject, observer } from 'mobx-react'

import { Button        } from 'sdc-react-basics'

export const StatusButton = inject('featuresStore')(observer(({featuresStore,feature=featuresStore.selected,icon,status}) =>
  <Button title={'feature.action.'+status} icon={icon} className={'status-'+status}
      active={feature.status === status}
      disabled={feature.status === status}
      onClick={featuresStore.updateStatus(feature,status)}
  />
))
