import React  from 'react'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { Label } from 'sdc-i18n-react'

import './feature-gallery.scss'

const Item = ({children,...props}) => <div className="gmcd-feature-gallery">
  {children}
</div>


export const FeatureGallery = () => {
  const settings = {
    arrows: true,
    autoplay: true,
    autoplaySpeed: 5000,
    dots: true,
    infinite: true,
    speed: 500,
    fade: true,
    slidesToShow: 1,
    slitesToScroll: 1,
  }
  return (
    <Slider {...settings}>
      <Item><h3>
        <div className="wrapper">
          <img src="/feedback-board-1-en.png" alt="Feedback Board" width="75%" height="75%"
               srcSet="/feedback-board-1-en-quart.png 480w, /feedback-board-1-en-half.png 959w, /feedback-board-1-en.png 1918w"></img></div>
        <div className="caption"><Label value="home.caption1" /></div>
      </h3></Item>
      {/*<Item><h3>
        <div className="wrapper">
          <img src="/progress.png" alt="Progress" width="50%" height="50%"
               srcSet="/progress-half.png 472w, /progress.png 944w"></img></div>
        <div className="caption"><Label value="home.caption2" /></div>
      </h3></Item>
      <Item><h3>
        <div className="wrapper">
          <img src="/wordcount.png" alt="Daily Wordcount" width="50%" height="50%"
               srcSet="/wordcount-half.png 475w, /wordcount.png 950w"></img></div>
        <div className="caption"><Label value="home.caption3" /></div>
      </h3></Item>*/}
    </Slider>
  )
}
