import React from 'react'
import { inject, observer } from 'mobx-react'
import { Provider         } from 'mobx-react'

import { Row, Col, Input  } from 'reactstrap'

import { Label            } from 'sdc-i18n-react'
import { EntryFieldsList  } from 'sdc-cms-editing'
import { editingType      } from 'sdc-cms-writing'

import { editingMode      } from 'sdc-cms-writing'
import { userStore        } from 'sdc-auth-user'
import { Button           } from 'sdc-react-basics'

import './boards.scss'


export const BoardDetails = inject('boardsStore')(observer(({boardsStore}) => {

    if (editingType.typeID !== boardsStore.typeID) return null

    const data = {
    store     : boardsStore,
    dataStore : boardsStore,
    update    : boardsStore.update,
  }

  return <Row className="board-details">
    <Col md="1" className="actions">
      {userStore.user.id && editingMode.isViewMode.get() && <Button icon="edit" onClick={editingMode.setMode('edit')} />}
      {userStore.user.id && editingMode.isEditMode.get() && <Button icon="eye"  onClick={editingMode.setMode('view')} />}
    </Col>
    <Col md="11">
      <Provider {...data}>
        <EntryFieldsList className="kasten-details" type="board" fields={['name','domain']} />
      </Provider>
    </Col>
  </Row>
}))
