import React from 'react'
import { inject, observer } from 'mobx-react'

import { DataTable } from '../components/table'
import * as column   from '../components/table/columns'
import { col, num, text } from '../components/table/columns'

const columns = store => ([
  column.createDelete({
    type     : store.name,
    onCreate : store.createFeature,
    onDelete : store.remove,
  }),
  text('title',{width: 250}),
  text('description'),
])

export const FeatureList = inject('featuresStore','boardsStore')(observer(({featuresStore,boardsStore}) =>
<DataTable store={featuresStore}
  columns={columns(featuresStore)}
  filter={feature => feature.board === boardsStore.selected?.id}
/>))
