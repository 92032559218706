import { toJS } from 'mobx'

import { userStore } from 'sdc-auth-user'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as apiQueries    from '../graphql/api-queries'
import * as subscriptions from '../graphql/subscriptions'

export default () => ({
  create : entry  => () => API.graphql({
    query     : mutations.createFeature,
    variables : { input : toJS(entry) },
    authMode  : 'API_KEY',
  }),
  list   : filter => () => API.graphql({
    query     : userStore.user.id ? queries.listFeatures : apiQueries.listFeatures,
    variables : {
      limit: 10000,
      filter,
    },
    authMode  : 'API_KEY',
  }),
  listMyFeatures : filter => () => API.graphql({
    query     : apiQueries.listMyFeatures,
    variables : {
      limit: 10000,
      filter,
    },
    authMode  : 'API_KEY',
  }),
  update : delta  => () => {
    if (delta.id) {
      return API.graphql({
        query: mutations.updateFeature,
        variables: { input : toJS(delta) },
        authMode  : 'API_KEY',
      })
    } else
      return API.graphql(graphqlOperation(mutations.createFeature, { input : toJS(delta) }))
  },
  vote : delta => () => API.graphql({
    query: mutations.updateFeature,
    variables: { input : toJS(delta) },
    authMode : 'API_KEY'
  }),
  remove : entry  => () => API.graphql(graphqlOperation(mutations.deleteFeature, { input : toJS({
    id: entry.id,
  }) })),
})
