import React from 'react'
import { inject, observer } from 'mobx-react'

import { Input } from 'reactstrap'

export const EmailInput = inject('labelsStore','myUserStore')(observer(({labelsStore,myUserStore}) =>
  <Input style={{marginTop: '2px', marginLeft: '4px'}} data-id={labelsStore.lastUpdate}
    placeholder={labelsStore.labelFor('email.placeholder')}
    title={labelsStore.labelFor('email.help')}
    value={myUserStore.email} onChange={myUserStore.handleEmailChange}
    onKeyDown={myUserStore.handleKeyDown} onBlur={myUserStore.submit}
  />
))
