import { toJS } from 'mobx'

import { API, graphqlOperation } from 'aws-amplify'
import * as mutations     from '../graphql/mutations'
import * as queries       from '../graphql/queries'
import * as apiQueries    from '../graphql/api-queries'
import * as subscriptions from '../graphql/subscriptions'

export default () => ({
  create : entry  => () => API.graphql({
    query     : mutations.createVote,
    variables : { input : toJS(entry) },
    authMode  : 'API_KEY',
  }),
  list : filter => () => API.graphql({
    query     : queries.listVotes,
    variables : {
      limit: 10000,
      filter,
    },
    authMode  : 'API_KEY',
  }),
  listVoteWeights : filter => () => API.graphql({
    query     : apiQueries.listVoteWeights,
    variables : {
      limit: 10000,
      filter,
    },
    authMode  : 'API_KEY',
  }),
  update : ({owner,email,feature,direction,...delta}) => () => API.graphql({
    query: mutations.updateVote,
    variables: { input : toJS(delta) },
    authMode  : 'API_KEY',
  }),
  remove : entry => () => API.graphql({
    query : mutations.deleteVote,
    variables: { input : toJS({
      id: entry.id,
    })},
    authMode  : 'API_KEY',
  }),
})
